import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

// i18n
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import i18next from "i18next";
import useRequests from "../../Utils/useRequests";

// Import Redux action
import { toggleLeftmenu } from "../../store/actions";

const Navbar = (props) => {
  const { getRequest } = useRequests();

  const activateParentDropdown = (menuItem) => {
    menuItem.classList.add("active");
  };

  const [AlertData, setAlertData] = useState({
    variant: "",
    message: "",
  });

  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((prev) => ({
        ...prev,
        variant: "",
        message: "",
      }));
    }, 5000);
  };

  useEffect(() => {
    let matchingMenuItem = null;
    let ul = document.getElementById("navigation");
    let items = ul.getElementsByTagName("a");

    for (let i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname]);

  // 📌 Close menu when clicking a menu item
  const handleMenuItemClick = () => {
    props.toggleLeftmenu(false);
  };

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/dashboard" onClick={handleMenuItemClick}>
                    <i className="bx bx-home-circle me-2"></i> {i18next.t("Menu Dashboard")}
                  </Link>
                </li>

                {localStorage.getItem("Collaborator") === "true" && (
                  <li className="nav-item">
                    <Link className="nav-link arrow-none" to="/collaborator" onClick={handleMenuItemClick}>
                      <i className="fas fa-handshake"></i> {i18next.t("Collaborator")}
                    </Link>
                  </li>
                )}

                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/wallet" onClick={handleMenuItemClick}>
                    <i className="bx bx-wallet-alt me-2"></i> {i18next.t("Menu My Wallet")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/properties" onClick={handleMenuItemClick}>
                    <i className="bx bx-shopping-bag me-2"></i> {i18next.t("Menu Browse Property")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/portfolio" onClick={handleMenuItemClick}>
                    <i className="bx bx-cart-alt me-2"></i> {i18next.t("Menu My Portfolio")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/claimed-fund" onClick={handleMenuItemClick}>
                    <i className="bx bx-money me-2"></i> {i18next.t("Menu My Claimed Fund")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/mytransaction" onClick={handleMenuItemClick}>
                    <i className="bx bx-list-ul me-2"></i> {i18next.t("Menu My Transaction")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/Kyc" onClick={handleMenuItemClick}>
                    <i className="bx bx-user-check me-2"></i> {i18next.t("Menu KYC Verification")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/twofaauthenticator" onClick={handleMenuItemClick}>
                    <i className="bx bx-key me-2"></i> {i18next.t("Menu 2FA Authenticator")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/dividends" onClick={handleMenuItemClick}>
                    <i className="bx bx-wallet-alt me-2"></i> {i18next.t("Menu Dividends")}
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.bool,
  location: PropTypes.object,
  toggleLeftmenu: PropTypes.func,
};

const mapStateToProps = (state) => ({
  leftMenu: state.Layout.leftMenu,
});

export default withRouter(connect(mapStateToProps, { toggleLeftmenu })(withTranslation()(Navbar)));
