import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Modal } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import moment from 'moment';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';
import USDT from "./usdt";
import { Input, Typography } from "@material-ui/core";

const series1 = [  { data: [0, 15, 20, 22, 30, 40, 50, 60, 70, 80, 100] }, ]
const series2 = [  { data: [0, 25, 26, 28, 35, 50, 80, 60, 50, 70, 100] }, ]

const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#036B41"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.5,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { enabled: false }, 
}

const Dashboard = props => {
  const history = useHistory();
  //meta title
  document.title = i18next.t('Dashboard PG document title');
	const location = useLocation();
  const { getRequest, postRequest, getRequestForBinance } = useRequests();


  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};


  const [Transaction, setTransaction] = React.useState([]);
  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async (elementData) => {
    setTransaction(elementData);
    setStandardModal1(true);
  }

  const [ ReferralLink, setReferralLink ] = React.useState('');
  const [ collaborator, setCollaborator ] = React.useState('false');
  const handleLoadGetUserInfo = async () => {
    const res = await getRequest('get-user-info');
    if (res && res.code === 200) {
      let originalText = res.data;
      
      setReferralLink(process.env.REACT_APP_SITE_URL+'register/'+originalText.referral_code);

      if(originalText.collaborator_referral_code){
        setCollaborator('true');
      }
    }
    else {
      // setAlertData((AlertData) => ({
      //   ...AlertData,
      //   variant: 'error',
      //   message: res.message,
      // }));
    }
    // resetAlertData();
  }

  const [FilePath, setFilePath] = React.useState('');
  const [TransactionTBody, setTransactionTBody] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{element.pct_id}</td>
        <td>{element.token}</td>
        <td>{element.currency}</td>
        <td>{parseFloat(element.received_amount)} &nbsp;{element.currency}</td>
        <td>{moment(element.created_at).format('YYYY-MM-DD | HH:mm')}</td>
        <td><span className={"font-size-14 badge bg-secondary rounded-pill " + (element.status === 'Pending' ? 'badge-soft-warning' : element.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{element.status}</span></td>
        <td className="text-center"><button type="button" className="btn-sm btn btn-primary" onClick={(e)=>togStandardModal1(element)}><i className="mdi mdi-eye"></i></button></td>
      </tr>
    ));

    setTransactionTBody(tempTBody);
  }
  
  const [ allProperyInvestment, setAllProperyInvestment ] = React.useState('');
  const handlePropertyInvestmentBody = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td><Link to={`/property-details/`+ btoa(element.property_id)} >{element.property_name}</Link></td>
        <td style={{ width: "200px", textAlign: "center"}}>{element.TotalValue}</td>
        <td style={{ width: "200px"}}>{element.TotalValueTokenPrice} €</td> 
        <td style={{ width: "200px"}}>{element.profitability} %</td> 
        <td style={{ width: "200px"}}>{((element.TotalValueTokenPrice*element.profitability)/100).toFixed(2)} €</td> 
        <td style={{ width: "200px"}}>{element.lock_in_period_no+" "+element.lock_in_period}</td> 
        <td style={{ width: "200px"}}>{element.expiry_date?new Date(element.expiry_date).toLocaleDateString('en-GB', { timeZone: 'Asia/Kolkata' }).split('/').join('-'):"-"}</td> 
        <td style={{ width: "130px"}}><Link to={`/property-claim-fund/`+ btoa(element.property_id)} >{i18next.t('Claim')}</Link></td>
      </tr>
    ));

    setAllProperyInvestment(tempTBody);
  }

  const handleLoadGetPropertyCryptoTransactionData = async () => {
    const res = await getRequest('get-property-crypto-transaction-data');
    if (res && res.code === 200) {
      handleT1Body(res.data.transaction);
      setFilePath(res.data.url);
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="7" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setTransactionTBody(tempTBody);
    }
  }

  const handleReferralLinkCopied = () => {
    setAlertData({
      variant: 'success',
      message: i18next.t('Link copied!'),
    });
    resetAlertData();
  };

  const [ currency, setcurrency ] = React.useState('Euro');

  const [euroBalanceData, setEuroBalanceData] = React.useState(0);
  const [usdtBalanceData, setUsdtBalanceData] = React.useState(0);
  const handleLoadGetCryptoData = async () => {
    const res = await getRequest('get-crypto-data');
    if (res && res.code === 200) {
      handleLoadGetPropertyInvestment(res.data);
    }
    else {
      // setAlertData((AlertData) => ({
      //   ...AlertData,
      //   variant: 'error',
      //   message: res.message,
      // }));
    }
    // resetAlertData();
  }

  const handleLoadGetPropertyInvestment = async (balance) => {
    const res = await getRequest('get-property-investment');
    if (res && res.code === 200) {

      let filteredCryptoTransaction = res.data.crypto_transaction.filter(transaction => transaction.is_claimed !== 1);

      const groupedTransactions = {};
      filteredCryptoTransaction.forEach(transaction => {
          const { property_id } = transaction;
      
          if (!groupedTransactions[property_id]) {
              groupedTransactions[property_id] = { transactions: [] };
          }
          groupedTransactions[property_id].transactions.push(transaction);
      });
      const propertiestransactions = Object.values(groupedTransactions);
      let AllPropertiesInvestment = []
      propertiestransactions.forEach(element => {
        let TotalValue = element.transactions.reduce((sum, transaction) => sum + parseFloat(transaction.value), 0);
        let TotalValueTokenPrice = element.transactions.reduce((sum, transaction) => {
            return sum + (parseFloat(transaction.value) * (parseFloat(transaction.token_price) || 0));
        }, 0);
        AllPropertiesInvestment.push({
          property_id:  element.transactions[0].property_id,
          property_name: element.transactions[0].property_name,
          property_token_symbol: element.transactions[0].property_token_symbol,
          profitability: element.transactions[0].profitability,
          lock_in_period_no: element.transactions[0].lock_in_period_no,
          lock_in_period: element.transactions[0].lock_in_period,
          expiry_date: element.transactions[0].expiry_date,
          TotalValue: TotalValue,
          TotalValueTokenPrice: TotalValueTokenPrice
        })
      });

      handlePropertyInvestmentBody(AllPropertiesInvestment);


      balance.euro_available_balance = Number(balance.euro_available_balance) + res.data.total_value_token_price;

      const binanceDataRates = await handleLoadGetBinanceRates();
      const EuroToUsdtRate = binanceDataRates.find(item => item.symbol === 'EUR' + 'USDT');
      const UsdtToEuroRate = 1 / parseFloat(EuroToUsdtRate.price);
      let usdt_Available_Balance= Number(balance.usdt_available_balance) + (Number(balance.euro_available_balance) * parseFloat(EuroToUsdtRate.price))
      let euro_Available_Balance = Number(balance.euro_available_balance) + (Number(balance.usdt_available_balance) * parseFloat(UsdtToEuroRate))
      setEuroBalanceData(euro_Available_Balance);
      setUsdtBalanceData(usdt_Available_Balance)
    }
    else {
      balance.euro_available_balance = Number(balance.euro_available_balance);

      const binanceDataRates = await handleLoadGetBinanceRates();
      const EuroToUsdtRate = binanceDataRates.find(item => item.symbol === 'EUR' + 'USDT');
      const UsdtToEuroRate = 1 / parseFloat(EuroToUsdtRate.price);
      let usdt_Available_Balance= Number(balance.usdt_available_balance) + (Number(balance.euro_available_balance) * parseFloat(EuroToUsdtRate.price))
      let euro_Available_Balance = Number(balance.euro_available_balance) + (Number(balance.usdt_available_balance) * parseFloat(UsdtToEuroRate))
      setEuroBalanceData(euro_Available_Balance);
      setUsdtBalanceData(usdt_Available_Balance);

      // setAlertData((AlertData) => ({
      //   ...AlertData,
      //   variant: 'error',
      //   message: res.message,
      // }));
    }
    // resetAlertData();
  }     

  const baseData = [0, 15, 20, 22, 30, 40, 50, 60, 70, 80, 100];
  const euro_scaleFactor = Number(euroBalanceData) / 10000;
  const usdt_scaleFactor = Number(usdtBalanceData) / 10000;

  const euro_series = [
    {
      data: baseData.map(value => Math.round(value * euro_scaleFactor) || 1),
    },
  ];

  const usdt_series = [
    {
      data: baseData.map(value => Math.round(value * usdt_scaleFactor) || 1),
    },
  ];               
  const balance = [ { euro: Number.isInteger((Number(euroBalanceData))) ? (Number(euroBalanceData)) : (Number(euroBalanceData)).toFixed(2), usdt: Number.isInteger((Number(usdtBalanceData))) ? (Number(usdtBalanceData)) : (Number(usdtBalanceData)).toFixed(2), euro_series: euro_series, usdt_series: usdt_series, options: options1, } ]

  const handleLoadGetBinanceRates = async () => {
    const res = await getRequestForBinance();
    if (res && res.code === 200) {
      return(res.data);
    }
  }
  

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const openModal = (content) => {
    closeFirstModal()
    setModalContent(content);
    setShowModal(true);
  };

  const closeModal = () => {
    setModalContent("");
    setShowModal(false);
  };

  const [showFirstModal, setFirstShowModal] = useState(false);
  const [modalFirstContent, setModalFirstContent] = useState("");

  const openFirstModal = (content) => {
    setModalFirstContent(content);
    setFirstShowModal(true);
  };

  const closeFirstModal = () => {
    setModalFirstContent("");
    setFirstShowModal(false);
  };

  const renderFirstModalContent = () => {
    if (modalFirstContent === "before-investing") {
      return (
        <>
          <div className="referal-title" onClick={() => openModal("kyc-process")}>
            <h4 className="card-title">1. {i18next.t('KYC Process')}</h4>
          </div>
          <div className="referal-title mt-3" onClick={() => openModal("google-authenticator")}>
            <h4 className="card-title">2. {i18next.t('Google Authenticator')}</h4>
          </div>
        </>
      );
    }
    if (modalFirstContent === "how-to-invest") {
      return (
        <>
          <div className="referal-title" onClick={() => openModal("bank-transfer")}>
            <h4 className="card-title">1. {i18next.t('Bank Transfer')}</h4>
          </div>
          <div className="referal-title mt-3" onClick={() => openModal("cryptocurrencies")}>
            <h4 className="card-title">2. {i18next.t('Cryptocurrencies')}</h4>
          </div>
        </>
      );
    }
    return null;
  };

  const renderModalContent = () => {
    if (modalContent === "kyc-process") {
      return (
        <>
          <div className="referal-title mb-3">
            <h4 className="card-title">{i18next.t('KYC Process')}</h4>
          </div>
          <iframe
            src="https://scribehow.com/embed/Como_completar_la_Verificacion_KYC__0VfQmvFqQ9uZSdIQS63TyQ?removeLogo=true&as=video"
            width="100%"
            height="640"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </>
      );
    }
    if (modalContent === "google-authenticator") {
      return (
        <>
          <div className="referal-title mb-3">
            <h4 className="card-title">{i18next.t('Google Authenticator')}</h4>
          </div>
          <iframe
            src="https://scribehow.com/embed/Activar_Google_Authenticator__etchUNnXRWGd-ukBBSfDhw?removeLogo=true&as=video"
            width="100%"
            height="640"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </>
      );
    }
    if (modalContent === "bank-transfer") {
      return (
        <>
          <div className="referal-title mb-3">
            <h4 className="card-title">{i18next.t('Bank Transfer')}</h4>
          </div>
          <iframe
            src="https://scribehow.com/embed/Invertir_por_Transferencia_Bancaria__Grd2ji8FS3mb9-jH6ZknDA?removeLogo=true&as=video"
            width="100%"
            height="640"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </>
      );
    }    
    if (modalContent === "cryptocurrencies") {
      return (
        <>
          <div className="referal-title mb-3">
            <h4 className="card-title">{i18next.t('Cryptocurrencies')}</h4>
          </div>
          <iframe
            src="https://scribehow.com/embed/Invertir_con_Criptomonedas___puQl1RnQZuLQTUSwMHX0g?removeLogo=true&as=video"
            width="100%"
            height="640"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </>
      );
    }
    return null;
  };

  const [onLoadModalOpen, setOnLoadModalOpen] = useState(false);
  const closeOnLoadModal = () => {
    localStorage.setItem("open_project_popup", "false");
    setOnLoadModalOpen(false);
  };

  const investModal = () => {
    localStorage.setItem("open_project_popup", "false");
    history.push("/properties")  
  };

  const [openProjectName, setOpenProjectName] = useState();
  const handleLoadGetProperties = async () => {
    const res = await getRequest('get-properties');
    if (res && res.code === 200) {
      let originalText = res.data.data;
      let imageurl = res.data.url;

      let openProperties= [];

      await originalText.forEach(element => {
        if(element.token_investment < element.total_token && element.property_status !== "funded") {
          openProperties.push(element);
        }
      });

      if (openProperties.length > 0) {
        let PropertyName= localStorage.getItem("I18N_LANGUAGE") === "eng" ? openProperties[openProperties.length - 1].property_name : openProperties[openProperties.length - 1].translated_property_name;
        setOpenProjectName(PropertyName);
        // const newImageUrl = `${process.env.REACT_APP_API_URL}${imageurl}${openProperties[openProperties.length - 1].cover_image}`
        // setTimeout(() => {
        //   const modalElement = document.querySelector(".modal-left");
        //   if (modalElement) {
        //       modalElement.style.backgroundImage = `url("${newImageUrl}")`;
        //   }
        // }, 1000)

        if(localStorage.getItem("open_project_popup")=="true"){
          setOnLoadModalOpen(true);
        }
      } else {
        setOnLoadModalOpen(false);
      }
      
    }
    else {
      setOnLoadModalOpen(false);
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  React.useEffect(() => {
    handleLoadGetProperties();
    handleLoadGetCryptoData();
    handleLoadGetUserInfo();
    handleLoadGetPropertyCryptoTransactionData();

    if (typeof location.state !== 'undefined' && typeof location.state.message !== 'undefined') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: location.state.status,
        message: location.state.message,
      }));
      resetAlertData();
		}
  }, []);


  return (
    <React.Fragment>
			{AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('PG breadcrumbItem Dashboard')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard" className="active"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  {
                    localStorage.getItem("Collaborator")=="true"?
                    <li><Link to="/collaborator"><i className="fas fa-handshake"></i> {i18next.t('Collaborator')}</Link></li>
                    :''
                  }
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/properties"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col xs="12">
                    <Row>

                    <Col className="balaceBox">
                      <USDT reports={balance} />
                    </Col>
                    
                  <Col lg="12">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-3 h4 card-title">{i18next.t('Portfolio')}</div>
                        <div className="table-responsive">
                          <table className="table mb-0"  style={{ minWidth: '940px' }}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('Token name')}</th>
                                <th style={{ width: "250px", textAlign: "center"}}>{i18next.t('Tokens')}</th>
                                <th style={{ width: "260px"}}>{i18next.t('Value')}</th>
                                <th style={{ width: "130px"}}>{i18next.t('Profitability')}</th>
                                <th style={{ width: "130px"}}>{i18next.t('Earnings')}</th>
                                <th style={{ width: "130px"}}>{i18next.t('Term')}</th>
                                <th style={{ width: "130px"}}>{i18next.t('ExpiryDate')}</th>
                                <th style={{ width: "130px", textAlign: "center"}}>{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allProperyInvestment}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Row>
                  <Col xs="12">
                    <Row>
                      <Col xl="12" md="12">
                        <div className="card">
                          <div className="card-body">
                            <div className="referal-title" onClick={() => openFirstModal("before-investing")}>
                              <h4 className="card-title">{i18next.t('What do I need to do before investing?')}</h4>
                            </div>
                            <div className="referal-title mt-3" onClick={() => openFirstModal("how-to-invest")}>
                              <h4 className="card-title">{i18next.t('How to invest?')}</h4>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                      <Col xl="12" md="12">
                        <div className="card">
                          <div className="card-body">
                            <div className="mb-3 h4 card-title">{i18next.t('Dashboard PG Referral Link')}</div>
                            <div className="Referal">
                              <p>{ReferralLink}</p>
                              <CopyToClipboard text={ReferralLink} onCopy={handleReferralLinkCopied}>
                                <i className="bx bx-copy-alt"></i>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-3 h4 card-title">{i18next.t('Latest Transaction')}</div>
                        <div className="table-responsive">
                          <table className="table mb-0" style={{ minWidth: '1200px' }}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('ID')}</th>
                                <th>{i18next.t('Tokens')}</th>
                                <th>{i18next.t('Currency')}</th>
                                <th>{i18next.t('Amount')}</th>
                                <th>{i18next.t('Date')}</th>
                                <th>{i18next.t('Status')}</th>
                                <th className="text-center">{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TransactionTBody}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


      <Modal isOpen={StandardModal1} backdrop={'static'} centered className="transation" >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{i18next.t('Transaction Details')}</h5>
          <button type="button" onClick={(e)=>setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p className="mb-3">{i18next.t('ID')} : <span className="text-primary">{Transaction.pct_id}	</span></p>
          <p className="mb-3">{i18next.t('Tokens')} : <span className="text-primary">{Transaction.token} </span></p>
          <p className="mb-3">{i18next.t('Currency')} : <span className="text-primary">{Transaction.currency} </span></p>
          <p className="mb-3">{i18next.t('Amount')} : <span className="text-primary">{parseFloat(Transaction.received_amount)} {Transaction.currency} {parseFloat(Transaction.used_euro_wallet_amount) !== 0? <b>( {Transaction.used_euro_wallet_amount} {i18next.t('My_Transaction PG EUR spent from euro wallet')} )</b> : '' }</span></p>
          <p className="mb-3">{i18next.t('Date')} : <span className="text-primary">{moment(Transaction.created_at).format('YYYY-MM-DD | HH:mm')}</span></p>
          <p className="mb-3">{i18next.t('Status')} : <span className={"font-size-14 badge bg-secondary rounded-pill " + (Transaction.status === 'Pending' ? 'badge-soft-warning' : Transaction.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{Transaction.status}</span></p>
          {Transaction.send_tx !== '' && Transaction.send_tx !== null?
          <p className="mb-3">{i18next.t('Transaction Hash')} : <span className="text-primary"><br />{Transaction.send_tx}</span></p>
          : '' }
          {Transaction.transaction_proof !== '' && Transaction.transaction_proof !== null?
          <p className="mb-3">{i18next.t('Transaction Proof')} : <span className="text-primary"><br /><a rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_API_URL}${FilePath}${Transaction.transaction_proof}`} download={Transaction.transaction_proof}>{Transaction.transaction_proof}</a></span></p>
          : '' }
          {Transaction.transaction_information !== '' && Transaction.transaction_information !== null?
          <p className="mb-3">{i18next.t('Transaction Information')} : <span className="text-primary"><br />{Transaction.transaction_information}</span></p>
          : '' }
        </div>
      </Modal>
      
      <Modal isOpen={showFirstModal} centered toggle={closeFirstModal} className="transation" >
        <div className="modal-header">
          <button type="button" onClick={(e)=>setFirstShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {renderFirstModalContent()}
        </div>
      </Modal>

      <Modal isOpen={showModal} centered toggle={closeModal} className="transation custom-modal">
        <div className="modal-header">
          <button type="button" onClick={(e)=>setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {renderModalContent()}
          </div>
      </Modal>

      <Modal isOpen={onLoadModalOpen} centered toggle={closeOnLoadModal} className="onLoadRegister">
        <div className="modal-full-width">
          <div className="modal-left">
            <p className="img-text">{openProjectName}</p>
          </div>
          <div className="modal-right">
            <div className="modal-header">
              <button type="button" onClick={closeOnLoadModal} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <Typography className="onLoadRegistertitle">{i18next.t('Open Project')}</Typography>
                <Typography className="onLoadRegisterdes">{i18next.t('Would you like to invest in the project ?')}</Typography>
                    {/* <div className="mb-3 fullwidth">
                      <Input name="email" type="text" placeholder={i18next.t('Login PG Enter email')} />
                    </div>
                    <div className="mb-3 fullwidth">
                      <Input name="email" type="text" placeholder={i18next.t('Login PG Enter email')} />
                    </div> */}
                    {/* <Link to="/properties" className="btn-sm btn btn-primary">{i18next.t('Invest')}</Link> */}
                <button type="button"  onClick={investModal} className="btn-sm btn btn-primary">{i18next.t('Invest')}</button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
