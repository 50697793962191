import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, TabContent, NavLink, TabPane } from "reactstrap";
import classname from "classnames";
import location from '../../assets/images/location-img.png';
import builder from '../../assets/images/builder-icon.png';
import loanOffer from '../../assets/images/discount-offer.png';

// Formik Validation
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import "../../assets/scss/custom/style.css";

import moment from 'moment';
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const MyTransaction = () => {

  //meta title
  document.title = i18next.t('My_Transaction PG document title');
  const { getRequest, postRequest } = useRequests();

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};


  const [Transaction, setTransaction] = React.useState([]);
  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async (elementData) => {
    setTransaction(elementData);
    setStandardModal1(true);
  }
  

  const [openPropertiesTBody, setOpenPropertiesTBody] = React.useState([]);
  const handleT1Body = async (tempData, imageurl) => {

    let openProperties=[]
    tempData.forEach(element => {
      let data= {
        coverImage: `${process.env.REACT_APP_API_URL}${imageurl}${element.cover_image}`,
        propertyName: localStorage.getItem("I18N_LANGUAGE") === "eng" ? element.property_name : element.translated_property_name,
        propertyAddress: localStorage.getItem("I18N_LANGUAGE") === "eng" ? element.property_address : element.translated_property_address,
        lockInPeriod: `${element.lock_in_period_no} ${element.lock_in_period=="year" ? i18next.t('year') : element.lock_in_period=="month" ? i18next.t('month') : i18next.t('day')}`,
        TotalTokenPrice: element.total_token * element.token_price,
        investors: element.investors,
        developerName: localStorage.getItem("I18N_LANGUAGE") === "eng" ? element.developerName : element.translated_developerName,
        propertyStatus: element.property_status,
        profitability: element.profitability,
        createdAt: moment(element.created_at).format('YYYY-MM-DD | HH:mm'),
        detailsLink: `/property-details/${btoa(element.p_id)}`
      }
      openProperties.push(data)
    });
    setOpenPropertiesTBody(openProperties);
  }

  const [fundedPropertiesTBody, setFundedPropertiesTBody] = React.useState([]);
  const handleT2Body = async (tempData, imageurl) => {

    let fundedProperties=[]
    tempData.forEach(element => {
      let data = {
        coverImage: `${process.env.REACT_APP_API_URL}${imageurl}${element.cover_image}`,
        propertyName: localStorage.getItem("I18N_LANGUAGE") === "eng" ? element.property_name : element.translated_property_name,
        propertyAddress: localStorage.getItem("I18N_LANGUAGE") === "eng" ? element.property_address : element.translated_property_address,
        lockInPeriod: `${element.lock_in_period_no} ${element.lock_in_period=="year" ? i18next.t('year') : element.lock_in_period=="month" ? i18next.t('month') : i18next.t('day')}`,
        TotalTokenPrice: element.total_token * element.token_price,
        investors: element.investors,
        developerName: localStorage.getItem("I18N_LANGUAGE") === "eng" ? element.developerName : element.translated_developerName,
        propertyStatus: element.property_status,
        profitability: element.profitability,
        createdAt: moment(element.created_at).format('YYYY-MM-DD | HH:mm'),
        detailsLink: `/property-details/${btoa(element.p_id)}`
      }
      fundedProperties.push(data)
    });
    setFundedPropertiesTBody(fundedProperties);
  }


  const handleLoadGetProperties = async () => {
    const res = await getRequest('get-properties');
    if (res && res.code === 200) {
      let originalText = res.data.data;
      let imageurl = res.data.url;

      let openProperties= [];
      let fundedProperties= [];

      await originalText.forEach(element => {
        if(element.token_investment < element.total_token && element.property_status !== "funded") {
          openProperties.push(element);
        } else {
          fundedProperties.push(element);
        }
      });
      if(openProperties.length === 0) {
        setOpenPropertiesTBody([]);
      } else {
        handleT1Body(openProperties, imageurl);
      }
      if(fundedProperties.length === 0) {
        setFundedPropertiesTBody([]);
      } else {
        handleT2Body(fundedProperties, imageurl);
      }
    }
    else {
      setOpenPropertiesTBody([]);
      setFundedPropertiesTBody([]);

      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  React.useEffect(() => {
    handleLoadGetProperties();

    // handleLoadGetPropertyCryptoTransactionData();
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('Properties')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  {
                    localStorage.getItem("Collaborator")=="true"?
                    <li><Link to="/collaborator"><i className="fas fa-handshake"></i> {i18next.t('Collaborator')}</Link></li>
                    :''
                  }                  
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/properties" className="active"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">

              <Row>
                  <Col lg="12">
                    <Nav tabs className="nav-tabs-custom mb-3" role="tablist">
                      <NavItem>
                        <NavLink className={classname({ active: activeTab === "1", })} onClick={() => { toggleTab("1"); }}>{i18next.t('Open Projects')}</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className={classname({ active: activeTab === "2", })} onClick={() => { toggleTab("2"); }}>{i18next.t('Funded Projects')}</NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="crypto-buy-sell-nav-content" >
                      <TabPane tabId="1" id="01">
                        <Row>
                          <Col xs={12}>
                            <Card>
                              <CardBody>
                              {openPropertiesTBody.length === 0 ? <div className="text-center">{i18next.t('No available projects at the moment')}</div> : ''}
                              {openPropertiesTBody.map((details, index) => (
                                <div className="projectsBoxLive" style={{
                                  height: '100%',
                                  width: '100%',
                                  backgroundImage: `url("${details.coverImage}")`, 
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center', position: 'relative'
                                }} key={index}>
                                  <div className="bg-black1"></div>
                                  <Row className="h-100">
                                    <Col xl={7} md={6} xs={12}>
                                      <p className="current-status">{i18next.t('Status')} : {details.propertyStatus=="open"?i18next.t('Open'):details.propertyStatus=="pre-reserve"?i18next.t('Pre-reserve'):i18next.t('Under-study')  }</p>
                                      <div className="clearfix"></div>
                                      <div className="detailsBottom">
                                        <p className="builder-name">{details.developerName}</p>
                                        <p className="project-location">{details.propertyAddress}</p>
                                        <p className="project-name">{details.propertyName}</p>
                                      </div>
                                    </Col>
                                    <Col xl={5} md={6} xs={12}>
                                      <div className="blur-bg">
                                        <Row className="h-100">
                                          <Col lg={4} xs={12}>
                                            <div className="more-details-box landscape">
                                              <p className="more-details-box-details1">{details.TotalTokenPrice.toLocaleString().replace(/,/g, '.')} €</p>
                                              <p className="more-details-box-details2">{i18next.t('Total')}</p>
                                            </div>
                                          </Col>
                                          <Col lg={4} xs={12}>
                                            <div className="more-details-box landscape">
                                              <p className="more-details-box-details1">{details.profitability.toLocaleString().replace(/\./g, ',')} %</p>
                                              <p className="more-details-box-details2">{i18next.t('Profitability')}</p>
                                            </div>
                                          </Col>
                                          <Col lg={4} xs={12}>
                                            <div className="more-details-box landscape">
                                              <p className="more-details-box-details1">{details.lockInPeriod}</p>
                                              <p className="more-details-box-details2">{i18next.t('Term1')}</p>
                                            </div>
                                          </Col>
                                          <Col xs={12}>
                                          <Link to={details.detailsLink}>
                                            {details.propertyStatus === "open" && (
                                              <button className="btn btn-primary bt-invertier">
                                                {i18next.t('Invest1')}
                                              </button>
                                            )}
                                            {/* <button className="btn btn-primary bt-invertier">{details.propertyStatus=="open"?i18next.t('Invest1'):details.propertyStatus==i18next.t('Pre-reserve')?"Pre-reserve":i18next.t('Are you interested')}</button> */}
                                          </Link>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2" id="02">
                        <Row>
                          <Col xs={12}>
                            <Card>
                            <CardBody>
                            {
                              fundedPropertiesTBody.map((details, index) => (
                              <div  className="projectsBoxLive1" key={index}>
                              <div className="projects-imgage">
                                <img src={details.coverImage} className="img-fluid projects-imges" />
                              </div>
                              <div className="projects-offers">
                                <div className="offersBox">
                                  <div className="offersBoxInfo">
                                    <p><img src={loanOffer} className="loanOffer" /><span>{i18next.t('Loan')}</span></p>
                                    <span>{i18next.t('Strategy')}</span>
                                  </div>
                                </div>
                                <div className="offersBox">
                                  <div className="offersBoxInfo">
                                    <p><span>{details.profitability.toLocaleString().replace(/\./g, ',')} %</span></p>
                                    <span>{i18next.t('Profitability')} {i18next.t('Total')}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="projects-des">
                                <Link to={details.detailsLink}>
                                <p className="projects-name">{details.propertyName}</p>
                                </Link>
                                <p className="more-info"><img src={location} /><span>{details.propertyAddress}</span></p>
                                <p className="more-info"><img src={builder} /><span> {i18next.t('Developer')} : {details.developerName}</span></p>
                              </div>

                              <div className="clearfix"></div>
                              <hr className="line-border" />
                              <div className="more-details">
                                <div className="more-details-box">
                                  <p className="more-details-box-details1">{details.TotalTokenPrice.toLocaleString().replace(/,/g, '.')} €</p>
                                  <p className="more-details-box-details2">{i18next.t('Funded')}</p>
                                </div>
                                <div className="more-details-box">
                                  <p className="more-details-box-details1">{details.investors}</p>
                                  <p className="more-details-box-details2">{i18next.t('Investors')}</p>
                                </div>
                                <div className="more-details-box">
                                  <p className="more-details-box-details1">{details.lockInPeriod}</p>
                                  <p className="more-details-box-details2">{i18next.t('Term1')}</p>
                                </div>
                              </div>
                              </div>
                            ))
                            }
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>













                {/* <Row>
                  <Col lg="12">
                    <div className="card">
                      <div className="card-body">
                      <div className="mb-3 h4 card-title">Open Projects</div>
                        <div className="table-responsive">
                          <table className="table mb-0" style={{minWidth: '1000px'}}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('ID')}</th>
                                <th>{i18next.t('Image')}</th>
                                <th>{i18next.t('Property Name')}</th>
                                <th>{i18next.t('Property Address')}</th>
                                <th>{i18next.t('Term')}</th>
                                <th>{i18next.t('Investment')}</th>
                                <th>{i18next.t('Investors')}</th>
                                <th>{i18next.t('Developer')}</th>
                                <th>{i18next.t('Status')}</th>
                                <th>{i18next.t('Profitability')}</th>
                                <th>{i18next.t('Date')}</th>
                                <th className="text-center">{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {openPropertiesTBody}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row> */}

                {/* <Row>
                  <Col lg="12">
                    <div className="card">
                      <div className="card-body">
                      <div className="mb-3 h4 card-title">Funded Projects</div>
                        <div className="table-responsive">
                          <table className="table mb-0" style={{minWidth: '1000px'}}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('ID')}</th>
                                <th>{i18next.t('Image')}</th>
                                <th>{i18next.t('Property Name')}</th>
                                <th>{i18next.t('Property Address')}</th>
                                <th>{i18next.t('Term')}</th>
                                <th>{i18next.t('Investment')}</th>
                                <th>{i18next.t('Investors')}</th>
                                <th>{i18next.t('Developer')}</th>
                                <th>{i18next.t('Profitability')}</th>
                                <th>{i18next.t('Date')}</th>
                                <th className="text-center">{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fundedPropertiesTBody}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
        </Container>

      </div>

    </React.Fragment>
  );
};

export default withRouter(MyTransaction);
